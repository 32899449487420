<template>
  <Deskwash />
</template>

<script>
import Deskwash from "../../../components/factory_deskwash";

export default {
  name: "factory_deskwash",
  components: {
    Deskwash,
  },
};
</script>